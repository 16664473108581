<template>
  <footer class="footer has-background-dark has-text-light pb-3">
    <div class="container">
      <div class="columns">
        <div class="column">
          <p class="is-uppercase is-size-4">
            Outraverse LLC
          </p>
          <figure class="image is-128x128 m-0">
            <img src="@/assets/img/outraverse-logo-light.png">
          </figure>
        </div>
        <div class="column">
          <div class="block">
            <ul class="is-list-style-none">
              <li class="list-item">
                <router-link to="/" class="is-footer-link has-text-light">
                  Home
                </router-link>
              </li>
              <li>
                <router-link to="/terms-of-services" class="is-footer-link has-text-light">
                  Terms of Services
                </router-link>
              </li>
              <li>
                <a href="/docs/outraverse-infographic.pdf" class="is-footer-link has-text-light" target="_blank">
                  Marketing Materials
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Copy Right -->
      <p class="has-text-centered">
        <font-awesome-icon icon="copyright" class="fa-fw" /> Copyright <router-link to="/" class="copyright-link animted-underline has-text-light">Outraverse</router-link> {{ copyRightYear }}
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    copyRightYear() {
      const start = 2022;
      let currentYear = new Date().getFullYear();
      let temp = `${start}`;
      if (currentYear > start) {
        temp += ` - ${currentYear}`
      }
      return temp;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/colors.scss";

.copyright-link {  
  background-image: linear-gradient($color-light 0 0);
}
.is-footer-link:hover {
  text-decoration: underline;
  text-shadow: 1px 2px 4px white;
}
</style>