const state = () => ({
  Menu: [
    {
      name: "blog", ext: true, url: "https://outraverse.github.io/"
    }
  ]
})

export default {
  namespaced: true,
  state
}