import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import "animate.css";
import firebase from "firebase/compat/app"
import "firebase/compat/analytics"
/* Add font awesome library */
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faChevronDown, faCopyright, faBars, faDatabase, faEarthAmericas, faFileCode } from "@fortawesome/free-solid-svg-icons";
import { faBtc } from "@fortawesome/free-brands-svg-icons";
library.add(faBtc, faChevronDown, faCopyright, faBars, faDatabase, faEarthAmericas, faFileCode);
dom.watch();

/**
 * Firebase configuration object
 */ 
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  projectId: process.env.VUE_APP_PROJECT_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};
//console.log(process.env)
//console.log(firebase)
//console.log(firebaseConfig)
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(store).use(router).provide("firebase", firebase).mount("#app")
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA })
//app.use(store).use(router).mount("#app")
